// Components
//
// Define common padding and border radius sizes and more.

//$line-height-lg:         (4 / 3) !default;
//$line-height-sm:         1.5 !default;
//
$border-radius: .125rem !default; // .25rem !default;
//$border-radius-lg:       .3rem !default;
$border-radius-sm: .0625rem !default; // .2rem !default;
//
//$component-active-color: #fff !default;
//$component-active-bg:    $brand-primary !default;
//
//$caret-width:            .3em !default;
//$caret-width-lg:         $caret-width !default;
