// Typography
//
// Font, line-height, and color for body text, headings, and more.

// https://www.google.com/design/spec/style/typography.html#typography-styles
// http://www.getmdl.io/styles/index.html

$font-family-sans-serif: 'Roboto', 'Helvetica', 'Arial', sans-serif !default;
//$font-family-serif:          Georgia, "Times New Roman", Times, serif !default;
//$font-family-monospace:      Menlo, Monaco, Consolas, "Courier New", monospace !default;
//$font-family-base:           $font-family-sans-serif !default;

// Pixel value used to responsively scale all typography. Applied to the `<html>` element.
//$font-size-root:             16px !default;
//
//$font-size-base:             1rem !default;
//$font-size-lg:               1.25rem !default;
//$font-size-sm:               .875rem !default;
//$font-size-xs:               .75rem !default;
//
//$font-size-h1:               2.5rem !default;
//$font-size-h2:               2rem !default;
//$font-size-h3:               1.75rem !default;
//$font-size-h4:               1.5rem !default;
//$font-size-h5:               1.25rem !default;
//$font-size-h6:               1rem !default;
//
$display1-size: 7rem !default; // md display-4 112px was 6rem;
$display2-size: 3.5rem !default; // md display-3 56px was 5.5rem
$display3-size: 2.8125rem !default; // md display-2 45px was 4.5rem
$display4-size: 2.125rem !default; // md display-1 34px was 3.5rem
//
//$display1-weight:             300 !default;
//$display2-weight:             300 !default;
//$display3-weight:             300 !default;
//$display4-weight:             300 !default;
//
//$line-height-base:                1.5 !default;
//
//$headings-margin-bottom:     ($spacer / 2) !default;
//$headings-font-family:       inherit !default;
$headings-font-weight: 400 !default; // was 500
//$headings-line-height:       1.1 !default;
//$headings-color:             inherit !default;
//
//$lead-font-size:             1.25rem !default;
//$lead-font-weight:           300 !default;
//
//$text-muted:                  $gray-light !default;
//
//$abbr-border-color:           $gray-light !default;
//
//$blockquote-small-color:      $gray-light !default;
//$blockquote-font-size:        ($font-size-base * 1.25) !default;
//$blockquote-border-color:     $gray-lighter !default;
//
//$hr-border-color:             rgba(0,0,0,.1) !default;
//$hr-border-width:             $border-width !default;
//
//$list-inline-padding:         5px !default;
//
//$dt-font-weight:              bold !default;
//
//$nested-kbd-font-weight:      bold !default;
