@import "variables";
$theme-colors: (primary: $prim, danger: $danger );

// Material

@import '../../node_modules/bootstrap-material-design/scss/variables';
@import '../../node_modules/bootstrap-material-design/scss/mixins';

// Classic
@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/mixins';

//@import '../../node_modules/bootstrap/scss/root'
@import '../../node_modules/bootstrap/scss/reboot';
//@import '../../node_modules/bootstrap/scss/type' // Already loaded by bootstrap-critical.sass !
//@import '../../node_modules/bootstrap/scss/images'
//@import '../../node_modules/bootstrap/scss/code'
//@import '../../node_modules/bootstrap/scss/grid' // Already loaded by bootstrap-critical.sass !
//@import '../../node_modules/bootstrap/scss/tables'
@import '../../node_modules/bootstrap/scss/forms';
@import '../../node_modules/bootstrap/scss/buttons';
//@import '../../node_modules/bootstrap/scss/transitions'
//@import '../../node_modules/bootstrap/scss/dropdown'
//@import '../../node_modules/bootstrap/scss/button-group'
//@import '../../node_modules/bootstrap/scss/input-group'
//@import '../../node_modules/bootstrap/scss/custom-forms'
//@import '../../node_modules/bootstrap/scss/nav'
//@import '../../node_modules/bootstrap/scss/navbar'
//@import '../../node_modules/bootstrap/scss/card'
//@import '../../node_modules/bootstrap/scss/breadcrumb'
//@import '../../node_modules/bootstrap/scss/pagination'
//@import '../../node_modules/bootstrap/scss/badge'
//@import '../../node_modules/bootstrap/scss/jumbotron'
@import '../../node_modules/bootstrap/scss/alert';
//@import '../../node_modules/bootstrap/scss/progress'
//@import '../../node_modules/bootstrap/scss/media'
//@import '../../node_modules/bootstrap/scss/list-group'
//@import '../../node_modules/bootstrap/scss/close'
//@import '../../node_modules/bootstrap/scss/toasts'
//@import '../../node_modules/bootstrap/scss/modal'
//@import '../../node_modules/bootstrap/scss/tooltip'
//@import '../../node_modules/bootstrap/scss/popover'
//@import '../../node_modules/bootstrap/scss/carousel'
//@import '../../node_modules/bootstrap/scss/spinners'
@import '../../node_modules/bootstrap/scss/utilities';
//@import '../../node_modules/bootstrap/scss/print'


// Material
//@import '../../node_modules/bootstrap-material-design/scss/reboot'
//@import '../../node_modules/bootstrap-material-design/scss/layout'
@import '../../node_modules/bootstrap-material-design/scss/buttons';
//@import '../../node_modules/bootstrap-material-design/scss/checkboxes'
//@import '../../node_modules/bootstrap-material-design/scss/custom-forms'
//@import '../../node_modules/bootstrap-material-design/scss/switches'
//@import '../../node_modules/bootstrap-material-design/scss/radios'
@import '../../node_modules/bootstrap-material-design/scss/forms';
//@import '../../node_modules/bootstrap-material-design/scss/input-group'
//@import '../../node_modules/bootstrap-material-design/scss/list-group'
//@import '../../node_modules/bootstrap-material-design/scss/tables'
//@import '../../node_modules/bootstrap-material-design/scss/nav'
//@import '../../node_modules/bootstrap-material-design/scss/navbar'
@import '../../node_modules/bootstrap-material-design/scss/alerts';
//@import '../../node_modules/bootstrap-material-design/scss/progress'
//@import '../../node_modules/bootstrap-material-design/scss/pagination'
//@import '../../node_modules/bootstrap-material-design/scss/type'
//@import '../../node_modules/bootstrap-material-design/scss/tooltip'
//@import '../../node_modules/bootstrap-material-design/scss/popover'
//@import '../../node_modules/bootstrap-material-design/scss/cards'
//@import '../../node_modules/bootstrap-material-design/scss/modal'
//@import '../../node_modules/bootstrap-material-design/scss/dropdown'
//@import '../../node_modules/bootstrap-material-design/scss/drawer'
//@import '../../node_modules/bootstrap-material-design/scss/themes'
//@import '../../node_modules/bootstrap-material-design/scss/ripples'
//@import '../../node_modules/bootstrap-material-design/scss/extensions'
