#contact {
  padding-top: $section-padding-top;
  padding-bottom: $section-padding-bot;

  input:focus,
  textarea:focus,
  button:focus {
    box-shadow: none; }

  textarea {
    min-height: 200px;
    padding-top: 15px; }

  .bmd-label-floating {
    color: $placeholder-color; }

  fieldset[disabled][disabled] {
    button, button:hover {
      background-color: #cacaca;
      color: $placeholder-color;
      cursor: initial; } }
  .alert {
    display: none; } }
