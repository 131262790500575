// Code

//$code-color:                  #bd4147 !default;
$code-bg: $grey-200 !default; // #f7f7f9 !default;

//$kbd-color:                   #fff !default;
//$kbd-bg:                      #333 !default;
//
//$pre-bg:                      #f7f7f9 !default;
//$pre-color:                   $gray-dark !default;
//$pre-border-color:            #ccc !default;
//$pre-scrollable-max-height:   340px !default;
