#competences {
  position: relative;
  z-index: 2;
  background-image: radial-gradient(circle, $skills-dots, $skills-dots 1px, #fff 1px, #fff);
  background-color: #fff;
  background-size: 28px 28px;
  padding-top: $section-padding-top;
  padding-bottom: $section-padding-bot;

  .about {
    h2, h3 {
      font-family: $font-family-base;
      font-weight: $font-weight-base;
      letter-spacing: 0 !important; }
    .title {
      font-size: 2.9rem !important; }
    .subtitle {
      font-size: 2.1rem; } }

  .stack-techno {
    position: relative;
    bottom: 0;
    transition: bottom 0.1s ease-in-out;
    &:hover {
      bottom: 7px; } }

  .tech .name {
    font-weight: bold;
    font-size: 1.1em; } }
