// Form states and alerts
//
// Define colors for form feedback states and, by default, alerts.
$state-success-text: $bmd-inverse !default;
$state-success-bg: theme-color(success) !default;

$state-info-text: $bmd-inverse !default;
$state-info-bg: theme-color(info) !default;

$state-warning-text: $bmd-inverse !default;
$state-warning-bg: theme-color(warning) !default;

$state-danger-text: $bmd-inverse !default;
$state-danger-bg: theme-color(danger) !default;
